import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);

  return authService.isLoggedIn().pipe(
    switchMap(isLoggedIn => {
      if (!isLoggedIn) {
        return of(false);
      }
      return of(true);
    })
  );
};
