<ng-container *ngIf="safeSrc; else errorTemplate">
  <iframe
    width="100%"
    height="100%"
    [src]="safeSrc"
    frameborder="0"
    allow="autoplay; fullscreen"></iframe>
</ng-container>
<ng-template #errorTemplate>
  <div class="w-full h-full flex items-center justify-center">
    <p>Erro ao carregar o conteúdo</p>
  </div>
</ng-template>
