import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { GlobalsService } from '../../../core/services/globals.service';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const toastr = inject(ToastrService);
  const globalService = inject(GlobalsService);
  const router = inject(Router);

  const authToken = sessionStorage.getItem('auth-token');
  const language =
    (localStorage.getItem('language') as 'en' | 'pt-br' | 'es' | null) ||
    'pt-br';

  const authReq = authToken
    ? req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Accept-Language': language,
        },
      })
    : req;

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = 'Ocorreu um erro desconhecido';

      if (error.status === 401) {
        errorMessage = 'Sua sessão expirou. Por favor, faça login novamente.';
        sessionStorage.clear();
        router.navigate(['/login']);
      } else if (error.error && error.error.message) {
        errorMessage = error.error.message;
      } else if (error.error && error.error.statusCode) {
        errorMessage =
          globalService.codeRequest[error.error.statusCode]?.[language] ||
          errorMessage;
      } else if (error.status) {
        errorMessage =
          globalService.codeRequest[error.status]?.[language] || errorMessage;
      }

      toastr.error(errorMessage);

      return throwError(() => new Error(errorMessage));
    })
  );
};
