import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericInputComponent } from '../../../../shared/components/generic-input/generic-input.component';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordServiceService } from '../../../../shared/services/reset-password-service.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [GenericInputComponent, CommonModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  token: string = '';
  unsubscribe$: Subject<void> = new Subject<void>();

  private updating = false;

  constructor(
    private route: ActivatedRoute,
    private resetPasswordService: ResetPasswordServiceService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        confirmPasswordValidator,
      ]),
      password_confirmation: new FormControl('', [
        Validators.required,
        confirmPasswordValidator,
      ]),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });

    this.form
      .get('password')
      ?.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateForm('password_confirmation');
      });

    this.form
      .get('password_confirmation')
      ?.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateForm('password');
      });
  }

  private updateForm(fieldName: 'password' | 'password_confirmation'): void {
    if (!this.updating) {
      this.updating = true;
      const otherField = this.form.get(
        fieldName === 'password' ? 'password_confirmation' : 'password'
      );
      otherField?.updateValueAndValidity();
      this.updating = false;
    }
  }

  resetPassword(): void {
    const password = this.form.get('password')?.value;
    const confirmation = this.form.get('password_confirmation')?.value;

    if (this.form.invalid || password !== confirmation) {
      return;
    }

    if (this.form.valid) {
      const { password, password_confirmation } = this.form.value;
      this.resetPasswordService
        .resetPasswordForm({
          password,
          password_confirmation,
          token: this.token,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.toastr.success('Senha redefinida com sucesso!');
            this.router.navigate(['/login']);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('password_confirmation');

  if (!password?.value || !passwordConfirm?.value) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  if (control === passwordConfirm) {
    return { passwordsNotMatching: true };
  }

  return null;
};
