import { Injectable } from '@angular/core';
import { CodeRequest } from '../../shared/interfaces/codeRequest.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  constructor() {}

  codeRequest: CodeRequest = {
    100: {
      en: 'Continue',
      'pt-br': 'Continuar',
      es: 'Continuar',
    },
    101: {
      en: 'Switching Protocols',
      'pt-br': 'Mudando Protocolos',
      es: 'Cambiando Protocolos',
    },
    102: {
      en: 'Processing',
      'pt-br': 'Processando',
      es: 'Procesando',
    },
    200: {
      en: 'Ok',
      'pt-br': 'Ok',
      es: 'Ok',
    },
    201: {
      en: 'Created',
      'pt-br': 'Criado',
      es: 'Creado',
    },
    202: {
      en: 'Accepted',
      'pt-br': 'Aceito',
      es: 'Aceptado',
    },
    203: {
      en: 'Non-Authoritative Information',
      'pt-br': 'Não autorizado',
      es: 'Información No Autorizada',
    },
    204: {
      en: 'No Content',
      'pt-br': 'Nenhum Conteúdo',
      es: 'Sin Contenido',
    },
    205: {
      en: 'Reset Content',
      'pt-br': 'Resetar Conteúdo',
      es: 'Restablecer Contenido',
    },
    206: {
      en: 'Partial Content',
      'pt-br': 'Conteúdo Parcial',
      es: 'Contenido Parcial',
    },
    300: {
      en: 'Multiple Choices',
      'pt-br': 'Múltipla Escolha',
      es: 'Múltiples Opciones',
    },
    301: {
      en: 'Moved Permanently',
      'pt-br': 'Movido Permanentemente',
      es: 'Movido Permanentemente',
    },
    302: {
      en: 'Found',
      'pt-br': 'Encontrado',
      es: 'Encontrado',
    },
    303: {
      en: 'See Other',
      'pt-br': 'Veja outro',
      es: 'Ver Otro',
    },
    304: {
      en: 'Not Modified',
      'pt-br': 'Não modificado',
      es: 'No Modificado',
    },
    305: {
      en: 'Use Proxy',
      'pt-br': 'Use Proxy',
      es: 'Usar Proxy',
    },
    306: {
      en: 'Proxy Switch',
      'pt-br': 'Proxy Trocado',
      es: 'Cambio de Proxy',
    },
    400: {
      en: 'Bad Request',
      'pt-br': 'Solicitação Inválida',
      es: 'Solicitud Incorrecta',
    },
    401: {
      en: 'Unauthorized',
      'pt-br': 'Não autorizado',
      es: 'No Autorizado',
    },
    402: {
      en: 'Payment Required',
      'pt-br': 'Pagamento necessário',
      es: 'Pago Requerido',
    },
    403: {
      en: 'Forbidden',
      'pt-br': 'Proibido',
      es: 'Prohibido',
    },
    404: {
      en: 'Not Found',
      'pt-br': 'Não encontrado',
      es: 'No Encontrado',
    },
    405: {
      en: 'Method Not Allowed',
      'pt-br': 'Método não permitido',
      es: 'Método No Permitido',
    },
    406: {
      en: 'Not Acceptable',
      'pt-br': 'Não aceito',
      es: 'No Aceptable',
    },
    407: {
      en: 'Proxy Authentication Required',
      'pt-br': 'Autenticação de Proxy Necessária',
      es: 'Autenticación de Proxy Requerida',
    },
    408: {
      en: 'Request Time-out',
      'pt-br': 'Tempo de solicitação esgotado',
      es: 'Tiempo de Solicitud Agotado',
    },
    409: {
      en: 'Conflict',
      'pt-br': 'Conflito',
      es: 'Conflicto',
    },
    410: {
      en: 'Gone',
      'pt-br': 'Perdido',
      es: 'Desaparecido',
    },
    411: {
      en: 'Length Required',
      'pt-br': 'Duração necessária',
      es: 'Longitud Requerida',
    },
    412: {
      en: 'Precondition Failed',
      'pt-br': 'Falha de pré-condição',
      es: 'Fallo en la Precondición',
    },
    413: {
      en: 'Request Entity Too Large',
      'pt-br': 'Solicitação da entidade muito extensa',
      es: 'Entidad de Solicitud Demasiado Grande',
    },
    414: {
      en: 'Request-URL Too Large',
      'pt-br': 'Solicitação de URL muito Longa',
      es: 'URL de Solicitud Demasiado Larga',
    },
    415: {
      en: 'Unsupported Media Type',
      'pt-br': 'Tipo de mídia não suportado',
      es: 'Tipo de Medio No Soportado',
    },
    416: {
      en: 'Request Range Not Satisfiable',
      'pt-br': 'Solicitação de faixa não satisfatória',
      es: 'Rango de Solicitud No Satisfactorio',
    },
    417: {
      en: 'Expectation Failed',
      'pt-br': 'Falha na expectativa',
      es: 'Expectativa Fallida',
    },
    500: {
      en: 'Internal Server Error',
      'pt-br': 'Erro do Servidor Interno',
      es: 'Error Interno del Servidor',
    },
    501: {
      en: 'Not Implemented',
      'pt-br': 'Não implementado',
      es: 'No Implementado',
    },
    502: {
      en: 'Bad Gateway',
      'pt-br': 'Porta de entrada ruim',
      es: 'Puerta de Enlace Incorrecta',
    },
    503: {
      en: 'Service Unavailable',
      'pt-br': 'Serviço Indisponível',
      es: 'Servicio No Disponible',
    },
    504: {
      en: 'Gateway Time-out',
      'pt-br': 'Tempo limite da Porta de Entrada',
      es: 'Tiempo de Espera de la Puerta de Enlace Agotado',
    },
    505: {
      en: 'HTTP Version Not Supported',
      'pt-br': 'Versão HTTP não suportada',
      es: 'Versión HTTP No Soportada',
    },
  };
}
