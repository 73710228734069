import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ResetPasswordResponse } from '../interfaces/resetPasswordResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordServiceService {
  private apiUrl = environment.urlApi;

  constructor(private http: HttpClient) {}

  resetPasswordForm(data: {
    password: string;
    password_confirmation: string;
    token: string;
  }): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(
      `${this.apiUrl}/auth/resetPasswordForm`,
      data
    );
  }
}
