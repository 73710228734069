import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-content-embedded',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dynamic-content-embedded.component.html',
  styleUrl: './dynamic-content-embedded.component.css',
})
export class DynamicContentEmbeddedComponent implements OnInit, OnDestroy {
  safeSrc: SafeResourceUrl | null = null;
  urlValmontQas: string = '';

  destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // Obter os parâmetros da URL
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      const page = params['page'];

      // Se o token e a página estiverem presentes nos parâmetros
      if (token && page) {
        // Armazenar o token e a página no sessionStorage
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('page', page);

        // Remover os parâmetros da URL usando replaceState
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          replaceUrl: true,
        });

        const currentUrl = window.location.hostname;

        if (environment.production) {
          if (currentUrl.includes('valleycheckpivot.com.br')) {
            this.urlValmontQas = 'https://api.valleycheckpivot.com.br';
          } else if (currentUrl.includes('valleycheckpivot.com')) {
            this.urlValmontQas = 'https://api.valleycheckpivot.com';
          } else {
            this.urlValmontQas = environment.urlValmontQa;
          }
        } else {
          this.urlValmontQas = environment.urlValmontQa;
        }

        // Criar a URL segura
        const url = `${this.urlValmontQas}/${page}?token=${token}`;
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      } else {
        // Se não houver parâmetros na URL, recuperar do sessionStorage
        const savedToken = sessionStorage.getItem('token');
        const savedPage = sessionStorage.getItem('page');

        if (savedToken && savedPage) {
          const url = `${this.urlValmontQas}/${savedPage}?token=${savedToken}`;
          this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
