import { Injectable } from '@angular/core';
import { ModuloAcessoInterface } from '../interfaces/moduloAcesso.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissoesService {
  private permissoes: ModuloAcessoInterface[] = [];

  constructor() {
    this.loadPermissions();
  }

  loadPermissions(): void {
    const storedPermissions = sessionStorage.getItem('modulos_acesso');
    if (storedPermissions) {
      this.permissoes = JSON.parse(
        storedPermissions
      ) as ModuloAcessoInterface[];
    }
  }

  hasPermission(name: string): boolean {
    return this.permissoes.some(
      modulo => modulo.name === name && modulo.permissions !== 0
    );
  }
}
