import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { PersonalDataPayload } from '../interfaces/profile/personalDataPayload.interface';
import { ChangePassword } from '../interfaces/profile/changePasswordPayload.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiUrl = environment.urlApi;

  constructor(private http: HttpClient) {}

  changePersonalData(
    id: number,
    payload: PersonalDataPayload
  ): Observable<string> {
    return this.http.put<string>(
      `${this.apiUrl}/changePersonalData/${id}`,
      payload
    );
  }

  changePassword(id: number, payload: ChangePassword): Observable<string> {
    return this.http.put<string>(
      `${this.apiUrl}/changePassword/${id}`,
      payload
    );
  }
}
