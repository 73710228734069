import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { Role } from '../../constants/role.enum';

export const adminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().pipe(
    switchMap(isLoggedIn => {
      if (!isLoggedIn) {
        router.navigate(['/login']);
        return of(false);
      }
      return authService.getUserRole().pipe(
        map(role => {
          if (role === Role.ADMIN) {
            return true;
          } else {
            router.navigate(['/auth/dashboard']);
            return false;
          }
        })
      );
    })
  );
};
