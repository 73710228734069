<app-header
  [title]="'PERFIL' | translate"
  [buttonBack]="false"
  [alignTitle]="'justify-between'"></app-header>

<app-loading
  [isLoading]="isLoading"
  [loadingMessage]="'Atualizando'"></app-loading>

<div
  class="grid grid-cols-[min-content,3fr,1.5fr,min-content] gap-10 mb-8 mr-6 ml-6"
  [class.opacity-50]="isLoading">
  <div></div>

  <div>
    <div class="text-[#2F5E73] text-[1.6rem] mb-5 text-center">
      <span class="font-medium uppercase">{{
        'DADOS_PESSOAIS' | translate
      }}</span>
    </div>

    <div class="grid grid-cols-2 gap-x-6 gap-y-1">
      <app-generic-input
        id="user_name"
        label=""
        controlName="user_name"
        [placeholder]="'NOME' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="email"
        label=""
        type="email"
        controlName="email"
        [placeholder]="'EMAIL' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="phone"
        label=""
        controlName="phone"
        [placeholder]="'PHONE' | translate"
        [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
        [form]="form">
      </app-generic-input>

      <app-input-select
        id="language"
        label=""
        controlName="language"
        [form]="form"
        [options]="selectOptions">
      </app-input-select>
    </div>
  </div>

  <div>
    <div class="text-[#2F5E73] text-[1.6rem] mb-5 text-center">
      <span class="font-medium uppercase">{{
        'ALTERAR_SENHA' | translate
      }}</span>
    </div>

    <div class="grid grid-cols-1 gap-1">
      <app-generic-input
        id="current_password"
        label=""
        type="password"
        controlName="password"
        [placeholder]="'SENHA_ATUAL' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="new_password"
        label=""
        type="password"
        controlName="new_password"
        [placeholder]="'NOVA_SENHA' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="password_confirmation"
        label=""
        type="password"
        controlName="password_confirmation"
        [placeholder]="'CONFIRMAR_SENHA' | translate"
        [form]="form">
      </app-generic-input>
    </div>
  </div>

  <div class="flex items-start justify-end w-20">
    <button
      type="submit"
      (click)="savePersonalData()"
      [disabled]="form.invalid"
      class="text-white font-bold rounded focus:outline-none focus:shadow-outline"
      [ngClass]="{
        'opacity-50 cursor-not-allowed': form.invalid
      }">
      <img
        [ngClass]="{
          'enabled-icon': form.valid,
          'disabled-icon': form.invalid
        }"
        src="assets/icons/save_button.svg"
        alt="Salvar"
        width="45"
        height="45" />
    </button>
  </div>
</div>
