<div class="h-screen bg-secondary flex flex-col justify-between">
  <div
    class="flex h-full flex-col items-center justify-center max-w-md mx-auto">
    <h1 class="text-2xl mb-1 font-semibold text-gray-600">Trocar Senha</h1>
    <h1 class="text-sm mb-1 font-medium text-gray-500">
      Coloque as duas senhas iguais
    </h1>

    <div class="w-72">
      <div class="flex flex-col gap-2 w-full">
        <div class="flex flex-col w-full">
          <app-generic-input
            id="password"
            label=""
            type="password"
            placeholder="Senha"
            controlName="password"
            [form]="form">
          </app-generic-input>
        </div>
        <div class="flex flex-col w-full">
          <app-generic-input
            id="password_confirmation"
            label=""
            placeholder="Confirme sua senha"
            type="password"
            controlName="password_confirmation"
            [form]="form">
          </app-generic-input>
        </div>
        <div class="flex flex-col w-full"></div>
      </div>

      <button
        (click)="resetPassword()"
        class="bg-primary hover:bg-blue-400 text-white px-4 py-2 rounded h-12 w-full">
        Confirmar
      </button>
    </div>
  </div>
</div>
